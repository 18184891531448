import { Button, Card, Table } from 'tabler-react'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router'
import { LoadStoreContext } from '../../contexts/LoadStoreContext'
import LoadPassengerListItem from './LoadPassengerListItem'
import useReactRouter from 'use-react-router'
import { useRootStore } from '../../hooks'
import { useLazyQuery } from '@apollo/client'
import { LOAD_LIST_QUERIES } from '../../graphql/LOAD_LIST_QUERIES'
import { formatDate } from '../../utils/dateFormat'
import { applySnapshot } from 'mobx-state-tree'
import AddLoadPassengerModal from './Form/AddLoadPassengerModal'
import FeatureAccessGate from 'modules/common/components/FeatureAccessGate'

const LoadPassengerList = () => {
  const { history } = useReactRouter()
  const { loadId } = useParams<{ loadId: string }>();
  const { loads } = useContext(LoadStoreContext)
  const { currentCoachTeam } = useRootStore()
  const [load, setLoad] = React.useState(null)
  const [passengers, setPassengers] = React.useState([])
  const [isModalOpen, setIsModalOpen] = useState(false)

  const [getLoads] = useLazyQuery(LOAD_LIST_QUERIES, {
    variables: {
      team_id: currentCoachTeam.id,
      date: formatDate(load?.load_departure ?? new Date())
    },
    onCompleted: (data) => {
      const foundLoad = data.loads.find((l) => loadId === l.id)
      setPassengers(foundLoad?.passengers)
      applySnapshot(loads, data.loads)
    }
  })

  useEffect(() => {
    const foundLoad = loads.find((l) => loadId === l.id)
    if (foundLoad) {
      setPassengers(foundLoad?.passengers)
      setLoad(foundLoad)
    } else history.push('/dzm/loads')
     
  }, [loadId, loads])

  const pilot = useMemo(
    () =>
      load?.passengers?.find(
        (pilot) => pilot.association_type_name === 'Pilot'
      ) ?? null,
    [load]
  )

  return (
    <Card>
      <Card.Header>
        <Card.Title>
          <span
            className="font-weight-bold cursor-pointer mr-1"
            onClick={() => history.push(`/dzm/loads`)}
          >
            Loads
          </span>
          // {load?.load_name}
          <Button outline color="secondary" size="sm" className="ml-3" disabled>
            {load?.aircraft?.name}
          </Button>
        </Card.Title>
        <Card.Options>
          <Button
            outline
            icon="user"
            color="warning"
            size="sm"
            className="mr-2"
            disabled
          >
            {' '}
            {pilot
              ? `${pilot.studentDetails.first_name} ${pilot.studentDetails.last_name}`
              : 'Add Pilot'}
          </Button>
          <Button
            outline
            icon="user-plus"
            color="primary"
            size="sm"
            className="mr-2"
            onClick={() => setIsModalOpen(true)}
          />
          <FeatureAccessGate feature="feature_alpha">
          <Button color="white" size="sm" className="text-danger" disabled>
            0/0
          </Button>
          <Button color="white" size="sm" className="text-danger" disabled>
            0 SLOTS AVAILABLE
          </Button>
          <Button color="white" size="sm" disabled>
            1 of {loads.length} Loads Today
          </Button>
          </FeatureAccessGate>
          <Button outline color="secondary" size="sm" className="mr-2" disabled>
            {load?.status.name}
          </Button>
          <Button
            outline
            icon="check-circle"
            color="success"
            size="sm"
            className=""
            // disable button until load is complete status // disabled
            disabled={load?.status.name !== 'Complete'}
          >
            Complete Load
          </Button>
        </Card.Options>
      </Card.Header>
      <Card.Body>
        <div className="table-responsive">
          <Table className="table card-table table-vcenter text-nowrap">
            <Table.Body>
              {passengers.map((passenger, index) => (
                <LoadPassengerListItem
                  passenger={passenger}
                  key={index}
                  load={load}
                  getLoads={getLoads}
                />
              ))}
            </Table.Body>
          </Table>
        </div>
        <AddLoadPassengerModal
          loadId={load?.id}
          isModalOpen={isModalOpen}
          toggleModal={() => setIsModalOpen(false)}
          currentPassengers={load?.passengers.map((p) => p.studentDetails.id)}
        />
      </Card.Body>
    </Card>
  )
}

export default LoadPassengerList;
