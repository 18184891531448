import { observer } from 'mobx-react'
import { clone } from 'mobx-state-tree'
import React, { useMemo, useState } from 'react'
import { Button, Dropdown, Icon, Table, Text } from 'tabler-react'

import UpdateInlineLoad from './Form/UpdateInlineLoad'
import AddLoadPassengerModal from './Form/AddLoadPassengerModal'
import useReactRouter from 'use-react-router'
import LoadJumps from './Modals/LoadJumps'

interface ILoadListItemProps {
  load: any
}

const LoadListItem = ({ load }: ILoadListItemProps) => {
    const { history } = useReactRouter();
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [loadClone, setLoadClone] = useState(clone(load));
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isJumpModalOpen, setIsJumpModalOpen] = useState(false);
  const jumps = useMemo(() => {
    let jumpList = load?.passengers.map((p) => p.jump_type_name);
    return (jumpList = jumpList
      .filter((jump, index) => jumpList.indexOf(jump) === index)
      .filter((x) => x))
  }, [load]);

  const onToggleEdit = () => {
    setIsEditing(true);
    setLoadClone(clone(load));
  };

  return isEditing ? (
    <UpdateInlineLoad loadClone={loadClone} setIsEditing={setIsEditing} />
  ) : (
    <>
      <LoadJumps
        isOpen={isJumpModalOpen}
        toggleModal={() => setIsJumpModalOpen(false)}
        jumps={jumps}
      />
      <Table.Col>
        <span
          className="text-primary text-decoration-none cursor-pointer"
          onClick={() => history.push(`/dzm/loads/passenger/${load.id}`)}
        >
          {load.load_name}
        </span>
      </Table.Col>
      <Table.Col>
        {load.load_departure &&
          new Date(load.load_departure).toLocaleTimeString()}
        <Text.Small className="d-block text-muted">
          {load.load_departure &&
            new Date(load.load_departure).toLocaleDateString()}
        </Text.Small>
      </Table.Col>
      <Table.Col>
        <span className="cursor-pointer" onClick={onToggleEdit}>
          {load.aircraft.name}
          <Text.Small className="d-block text-muted">
            {load.aircraft.tail_number} {load.aircraft.aircraft_type}
          </Text.Small>
        </span>
      </Table.Col>
      <Table.Col
        className={
          load.passengers.length < load.aircraft.capacity_min
            ? 'text-danger'
            : 'text-success'
        }
      >
        <span>{load.passengers.length}</span>/{load.aircraft.capacity_max}
      </Table.Col>
      <Table.Col>
        <React.Fragment>
          <Text
            className={
              load.status.name === 'Complete'
                ? 'status-icon bg-success'
                : load.status.name === 'Shutdown'
                ? 'status-icon bg-danger'
                : 'status-icon bg-secondary'
            }
          />{' '}
          <span className="cursor-pointer" onClick={onToggleEdit}>
            {load.status.name}
          </span>
        </React.Fragment>
      </Table.Col>
      <Table.Col className="text-right">
        <React.Fragment>
          <Button
            size="sm"
            color="white"
            className="mr-3"
            onClick={() => history.push(`/dzm/loads/passenger/${load.id}`)}
          >
            <Icon name="users" className="text-success" />
          </Button>
          <Button
            icon="user-plus"
            size="sm"
            color="white"
            className="mr-3 text-info"
            onClick={() => setIsModalOpen(true)}
            disabled={load.passengers.length >= load.aircraft.capacity_max}
          >
            Jumper
          </Button>
          <Button.Dropdown value="Actions" color="secondary" size="sm">
            <Dropdown.Item
              icon="edit"
              className="cursor-pointer"
              onClick={onToggleEdit}
            >
              Edit
            </Dropdown.Item>
            <Dropdown.Item icon="user-plus" className="cursor-pointer" onClick={() => setIsModalOpen(true)}>
              Manifest Jumper
            </Dropdown.Item>
            <Dropdown.Item icon="list" className="cursor-pointer" onClick={() => setIsJumpModalOpen(true)}>
              Jump Types
            </Dropdown.Item>
            <Dropdown.Item icon="x-circle" className="text-muted">
              Delete
            </Dropdown.Item>
          </Button.Dropdown>
        </React.Fragment>
      </Table.Col>
      <AddLoadPassengerModal
        loadId={load.id}
        isModalOpen={isModalOpen}
        toggleModal={() => setIsModalOpen(false)}
        currentPassengers={load.passengers.map((p) => p.studentDetails.id)}
      />
    </>
  );
};

export default observer(LoadListItem);
