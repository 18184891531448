import { useField } from "formik";
import React from "react";

export enum EInputTypes {
  checkbox = "checkbox",
  radio = "radio",
  text = "text",
}

export interface IFormFieldProps {
  additionalgroupclasses?: string[];
  additionalinputclasses?: string[];
  appendright?: JSX.Element;
  appendleft?: JSX.Element;
  appendbottom?: JSX.Element;
  checked?: boolean;
  disabled?: boolean;
  displayerrormessage?: string;
  label?: string;
  multiline?: string;
  name?: string;
  placeholder?: string;
  type?: string;
  value?: string | number;
  onBlur?: (_: any) => void;
  onChange?: (_: any) => void;
  min?: string | number;
  max?: string | number;
  readOnly?: boolean;
  rows?: number;
}

const FormField: React.FunctionComponent<IFormFieldProps> = (
  props: IFormFieldProps,
) => {
  const [field, meta] = useField(props.name);

  const additionalgroupclasses =
    props.additionalgroupclasses && props.additionalgroupclasses.join(" ");

  const inputElement =
    props.multiline === "true" ? (
      <textarea
        className={`form-control ${props.additionalinputclasses} ${
          meta.error && meta.touched && "is-invalid state-invalid"
        }`}
        {...field}
        {...props}
      />
    ) : (
      <input
        className={`form-control ${props.additionalinputclasses} ${
          props.displayerrormessage === "true" &&
          meta.error &&
          meta.touched &&
          "is-invalid state-invalid"
        }`}
        {...field}
        {...props}
      />
    );

  return (
    <div className={`form-group ${additionalgroupclasses}`}>
      {props.label && <label className="form-label">{props.label}</label>}
      <div className="input-group">
        {props.appendleft}
        {inputElement}
        {props.appendright}
      </div>
      {props.appendbottom}
      {props.displayerrormessage === "true" && (
        <span className="field-error text-danger">
          {meta.error && meta.touched && meta.error}
        </span>
      )}
    </div>
  );
};

FormField.defaultProps = {
  additionalgroupclasses: [],
  additionalinputclasses: [],
  appendright: <div />,
  multiline: "false",
  displayerrormessage: "true",
};

export default FormField;
