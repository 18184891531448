import Modal from "components/Modal";
import EventOrganizersSelectForm from "modules/user/roles/components/forms/EventOrganizersSelectForm";
import React, { useState } from "react";
import { Button } from "tabler-react";
import useGetCurrentUserType from "modules/common/hooks/useGetCurrentUserType";

const EventOrganizersButton = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { isAdmin, isCoachSuperAdmin  } = useGetCurrentUserType();
  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  return (
    <>
      <Button
        icon="users"
        color="white"
        className="text-muted"
        onClick={toggleModal}
        disabled={!isAdmin || !isCoachSuperAdmin}
      >
        Organizers
      </Button>
      <Modal
        title="Event Organizers"
        content={<EventOrganizersSelectForm />}
        open={isModalOpen}
        onClose={toggleModal}
      />
    </>
  );
};

export default EventOrganizersButton;
