import { useLazyQuery } from "@apollo/client";
import { EUserFilterOptions } from "components/enum";
import CoachStudentList from "components/Students/CoachStudentList";
import { GET_STUDENTS_BY_FILTER_QUERY } from "graphql/GET_STUDENTS_BY_FILTER_QUERY";
import { GET_STUDENTS_BY_FILTER_QUERY as GET_STUDENTS_BY_FILTER_QUERY_TYPE } from "graphql/types/GET_STUDENTS_BY_FILTER_QUERY";
import { EOrderOptions } from "hooks/useAutocomplete";
import { useRootStore } from "hooks/useRootStore";
import FeatureAccessGate from "modules/common/components/FeatureAccessGate";
import useGetCurrentUserType from "modules/common/hooks/useGetCurrentUserType";
import Wrapper from "pages/layout";
import React from "react";
import { CSVDownload } from "react-csv";
import { Button, Card, Container, Icon } from "tabler-react";
import { formatDateToLocalString } from "utils/dateFormat";
import AddStudentCoachModal from "../../components/Students/Modal/AddStudentCoachModal";

const StudentsPageCSVDownload = () => {
  const { currentCoachTeam } = useRootStore();
  const [loadStudents, { data, loading }] =
    useLazyQuery<GET_STUDENTS_BY_FILTER_QUERY_TYPE>(
      GET_STUDENTS_BY_FILTER_QUERY,
    );

  const loadData = async () => {
    await loadStudents({
      variables: {
        order: EOrderOptions.newest,
        filter: {
          team_id: currentCoachTeam?.id.toString(),
          filter_by: EUserFilterOptions.enabled,
        },
      },
    });
  };

  let students = [];
  if (data?.students && !loading) {
    students =
      data?.students.map(
        ({
          id,
          first_name,
          last_name,
          phone_country_code,
          phone_number,
          email,
          current_balance,
          last_jump,
          last_tunnel_session,
          last_event,
        }) => ({
          id,
          first_name,
          last_name,
          phone_country_code,
          phone_number,
          email,
          current_balance,
          last_jump: formatDateToLocalString(last_jump),
          last_tunnel_session: formatDateToLocalString(last_tunnel_session),
          last_event: formatDateToLocalString(last_event),
        }),
      ) ?? [];
  }

  if (loading) {
    return (
      <Button loading color="secondary">
        Processing...
      </Button>
    );
  }

  return (
    <>
      {students.length > 0 && (
        <CSVDownload data={students} filename="students.csv" />
      )}
      <Button
        outline
        onClick={loadData}
        icon="download-cloud"
        color="secondary"
        disabled={loading}
      >
        CSV
      </Button>
    </>
  );
};

const StudentsPage = (props) => {
  const { isAdmin, isCoachAdmin, isCoachSuperAdmin } = useGetCurrentUserType();
  const { currentCoachTeam } = useRootStore();
  const [isAddStudentModalOpen, setIsAddStudentModalOpen] =
    React.useState(false);
  const handleToggleAddStudentModal = () =>
    setIsAddStudentModalOpen(!isAddStudentModalOpen);

  return (
    <Wrapper {...props} title="Students">
      <AddStudentCoachModal
        isOpen={isAddStudentModalOpen}
        handleClose={handleToggleAddStudentModal}
      />
      <Container>
        <Card>
          <Card.Header>
            <Card.Title>
              <Icon name="users" className="mr-2 ml-0 text-blue" />
              Students
            </Card.Title>
            <Card.Options>
              <Button.List align="right" className={"ml-2"}>
                <FeatureAccessGate feature="feature_open_add_students">
                  {(isCoachAdmin || isCoachSuperAdmin) && (
                    <Button
                      outline
                      icon={"user-plus"}
                      color={"primary"}
                      disabled={isAddStudentModalOpen}
                      onClick={handleToggleAddStudentModal}
                    >
                      Add Student
                    </Button>
                  )}
                </FeatureAccessGate>
                {(isCoachAdmin || isCoachSuperAdmin || isAdmin) && (
                  <StudentsPageCSVDownload />
                )}
              </Button.List>
            </Card.Options>
          </Card.Header>
        </Card>
        <Card>
          <Card.Body>
            <CoachStudentList />
          </Card.Body>
        </Card>
      </Container>
    </Wrapper>
  );
};

export default StudentsPage;
