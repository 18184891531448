import { useMutation, useQuery } from "@apollo/client";
import Modal from "components/Modal";
import StudentLink from "components/Students/StudentLink";
import AccountBalance from "components/User/AccountBalance";
import { EUserTypes } from "components/User/userTypes";
import { Formik } from "formik";
import { ACCOUNT_BALANCE_TRANSACTION } from "graphql/ACCOUNT_BALANCE_TRANSACTION";
import { GET_ACCOUNT_BALANCE } from "graphql/GET_ACCOUNT_BALANCE";
import { GET_ACCOUNT_TYPE_BY_TEAM_ID } from "graphql/GET_ACCOUNT_TYPE_BY_TEAM_ID";
import { GET_STUDENTS_ON_DASHBOARD } from "graphql/GET_STUDENTS_ON_DASHBOARD";
import { REMOVE_STUDENT_FROM_DASHBOARD } from "graphql/REMOVE_STUDENT_FROM_DASHBOARD";
import { GET_ACCOUNT_BALANCE as GET_ACCOUNT_BALANCE_TYPE } from "graphql/types/GET_ACCOUNT_BALANCE";
import { GET_ACCOUNT_TYPE_BY_TEAM_ID as GET_ACCOUNT_TYPE_BY_TEAM_ID_TYPE } from "graphql/types/GET_ACCOUNT_TYPE_BY_TEAM_ID";
import { useRootStore } from "hooks";
import { observer } from "mobx-react";
import React, { useMemo, useState } from "react";
import { toast } from "react-toastify";
//import useReactRouter from 'use-react-router';
import {
  Button,
  Card,
  Dropdown,
  Form,
  Grid,
  Header,
  Icon,
  Progress,
  Text,
} from "tabler-react";
import * as Yup from "yup";

interface IOnDeckListItemProps {
  onDeck: any;
  isEditable?: boolean;
}

const OnDeckListItem = ({ onDeck, isEditable }: IOnDeckListItemProps) => {
  const { currentUser, currentCoachTeam } = useRootStore();
  //const { history } = useReactRouter();

  const [isCustomInputOpen, setIsCustomInputOpen] = useState(false);

  const toggleCustomInput = () => {
    setIsCustomInputOpen(!isCustomInputOpen);
  };

  const [accountBalanceTransaction] = useMutation(ACCOUNT_BALANCE_TRANSACTION, {
    onCompleted: () => toast.success("Minutes removed from account."),
  });

  const [removeStudentFromDashboard] = useMutation(
    REMOVE_STUDENT_FROM_DASHBOARD,
    {
      onCompleted: () => toast.success("User removed from deck."),
    },
  );

  const { data: accountBalance } = useQuery<GET_ACCOUNT_BALANCE_TYPE>(
    GET_ACCOUNT_BALANCE,
    {
      variables: {
        studentId: onDeck.studentDetails.id,
        teamId: currentCoachTeam.id,
      },
    },
  );

  const { data: accountType, loading: accountTypeLoading } =
    useQuery<GET_ACCOUNT_TYPE_BY_TEAM_ID_TYPE>(GET_ACCOUNT_TYPE_BY_TEAM_ID, {
      variables: {
        teamId: currentCoachTeam.id,
      },
    });

  const accountTypeId = useMemo(() => {
    if (accountType?.getAccountTypeByTeamId) {
      return accountType.getAccountTypeByTeamId.id;
    }
  }, [accountType?.getAccountTypeByTeamId, accountTypeLoading]);

  const handleRemoveFromDashboard = async ({ studentDetails }) => {
    if (
      window.confirm(
        `Are you sure you want to remove ${studentDetails.first_name} ${studentDetails.last_name}?`,
      )
    ) {
      await removeStudentFromDashboard({
        variables: {
          team_id: currentCoachTeam.id,
          student_id: studentDetails.id,
        },
        refetchQueries: [
          {
            query: GET_STUDENTS_ON_DASHBOARD,
            variables: {
              team_id: currentCoachTeam.id,
            },
          },
        ],
      });
    }
  };

  const handleRemoveMinutes = async (minutes, student) => {
    if (
      window.confirm(
        `Are you sure you want to remove ${minutes} minutes from ${student.first_name} ${student.last_name}?`,
      )
    ) {
      await accountBalanceTransaction({
        variables: {
          payload: {
            account_type_id: accountTypeId,
            created_by: currentUser.id,
            minutes: -minutes,
            student_id: onDeck.studentDetails.id,
            team_id: currentCoachTeam.id,
            transaction_type_id: 2,
          },
        },
        refetchQueries: [
          {
            query: GET_ACCOUNT_BALANCE,
            variables: {
              studentId: onDeck.studentDetails.id,
              teamId: currentCoachTeam.id,
            },
          },
        ],
      });
    }
  };

  let dropdown_items = [];

  if (
    currentCoachTeam.account_type_slug === "time" &&
    isEditable &&
    onDeck.studentDetails.registration_id
  ) {
    dropdown_items = [
      {
        value: (
          <span className="cursor-pointer text-gray-dark">
            <i className="fe fe-clock mr-2 text-muted" />
            -0:10
          </span>
        ),
        onClick: () => handleRemoveMinutes(10, onDeck.studentDetails),
      },
      {
        value: (
          <span className="cursor-pointer text-gray-dark">
            <i className="fe fe-clock mr-2 text-muted" />
            -0:15
          </span>
        ),
        onClick: () => handleRemoveMinutes(15, onDeck.studentDetails),
      },
      {
        value: (
          <span className="cursor-pointer text-gray-dark">
            <i className="fe fe-clock mr-2 text-muted" />
            Custom
          </span>
        ),
        onClick: () => toggleCustomInput(),
      },
    ];
  }
  
  if (
    currentCoachTeam.account_type_slug === "time" &&
    !onDeck.studentDetails.registration_id
  ) {
    dropdown_items = [
      {
        value: (
          <span className="cursor-pointer text-muted">
            <i className="fe fe-alert-circle mr-2 text-muted" />
            Unregistered User
          </span>
        ),
      //  onClick: () => handleRemoveMinutes(10, onDeck.studentDetails),
      },
    ];
  }

  const onDragStart = (e) => {
    e.dataTransfer.setData("studentId", onDeck.studentDetails.id);
  };

  return (
    <Grid.Col className="pl-0 pr-0" lg={12}>
      <div
        draggable={isEditable && onDeck.studentDetails.registration_id}
        onDragStart={
          isEditable && onDeck.studentDetails.registration_id && onDragStart
        }
      >
        <Card>
          <Card.Status color={!onDeck.studentDetails.registration_id ? 'danger' : 'primary'} />
          <Card.Body>
            <Dropdown
              className="float-right"
              toggle={false}
              arrow
              triggerContent={<Icon name="chevron-down" />}
              itemsObject={[
                ...dropdown_items,
                {
                  /*
                  value:
                    <span className="cursor-pointer">
                      <i className='fe fe-user mr-2 text-muted' />
                      Account
                    </span>,
                  onClick: () => history.push(`/user-details/${onDeck.studentDetails.id}/student-teams/${currentCoachTeam.id}`)
              */
                },
                {
                  value: (
                    <span className="text-danger cursor-pointer">
                      <i className="fe fe-minus-circle mr-2 text-danger" />
                      Remove
                    </span>
                  ),
                  onClick: () => handleRemoveFromDashboard(onDeck),
                },
              ]}
            />
            <Text muted>
              <AccountBalance
                accountBalance={
                  accountBalance?.getAccountBalance?.total_time ?? 0
                }
                slug={currentCoachTeam.account_type_slug}
              />
            </Text>
            <Header.H4 className="mb-1">
              <Icon
                name={`${
                  onDeck.studentDetails.registration_id
                    ? "user-check"
                    : "user-x"
                }`}
                className={`${
                  onDeck.studentDetails.registration_id
                    ? "text-success"
                    : "text-danger"
                } mr-1`}
              />

              <StudentLink
                studentId={onDeck.studentDetails.id}
                studentName={`${onDeck.studentDetails.first_name} ${onDeck.studentDetails.last_name}`}
              />
            </Header.H4>
            {currentCoachTeam.account_type_slug !== "time" && (
              <>
                <Text.Small className={`text-black`}>
                  {currentUser.type === EUserTypes.dzm
                    ? `${0} Jumps Today`
                    : currentUser.type === EUserTypes.coach.toString()
                    ? `${0} Flights Today`
                    : ""}{" "}
                  {/* todo: create API and graphql for this*/}
                </Text.Small>
                <Progress size="sm">
                  <Progress.Bar color="blue" width={0} />
                </Progress>
              </>
            )}
          </Card.Body>
        </Card>
      </div>

      <Modal
        maxWidth="xs"
        content={
          <Formik
            enableReinitialize={true}
            validationSchema={Yup.object().shape({
              minutes: Yup.number().required("Required"),
            })}
            initialValues={{
              minutes: "",
            }}
            onSubmit={async (values, { resetForm, setSubmitting }) => {
              const { minutes } = values;

              try {
                await accountBalanceTransaction({
                  variables: {
                    payload: {
                      account_type_id: accountTypeId,
                      created_by: currentUser.id,
                      minutes: -minutes,
                      student_id: onDeck.studentDetails.id,
                      team_id: currentCoachTeam.id,
                      transaction_type_id: 2,
                    },
                  },
                  refetchQueries: [
                    {
                      query: GET_ACCOUNT_BALANCE,
                      variables: {
                        studentId: onDeck.studentDetails.id,
                        teamId: currentCoachTeam.id,
                      },
                    },
                  ],
                });
              } catch (error) {
                toast.error(error);
              }

              setSubmitting(false);
              toggleCustomInput();

              resetForm();
            }}
          >
            {({ handleSubmit, setFieldValue }) => (
              <form onSubmit={handleSubmit} className="d-flex flex-column">
                <Form.Input
                  autoFocus
                  name="minutes"
                  type="number"
                  placeholder=""
                  className="text-center text-xxl"
                  onChange={(e) => {
                    setFieldValue("minutes", Number(e.target.value));
                  }}
                />
                <div className="d-flex justify-content-end mt-3">
                  <Button
                    pill
                    type="button"
                    color="white"
                    size="sm"
                    className="mr-2"
                    onClick={toggleCustomInput}
                  >
                    CANCEL
                  </Button>
                  <Button pill type="submit" color="primary" size="sm">
                    REMOVE
                  </Button>
                </div>
              </form>
            )}
          </Formik>
        }
        open={isCustomInputOpen}
        title={""}
        onClose={toggleCustomInput}
      />
    </Grid.Col>
  );
};

export default observer(OnDeckListItem);
