import { useMutation, useQuery } from "@apollo/client";
import React from "react";
import { toast } from "react-toastify";
import { Card, Form, Grid } from "tabler-react";
import { CREATE_CAMP_REGISTRATION_REQUIREMENT } from "../../../graphql/CREATE_CAMP_REGISTRATION_REQUIREMENT";
import { DELETE_CAMP_REGISTRATION_REQUIREMENT } from "../../../graphql/DELETE_CAMP_REGISTRATION_REQUIREMENT";
import { GET_CAMP_REGISTRATION_REQUIREMENTS } from "../../../graphql/GET_CAMP_REGISTRATION_REQUIREMENTS";
import { useRootStore } from "../../../hooks";
import { Types } from "../../../types/graphql";

interface ICalendarEventRequirementsProp {
  event: Types.Camp;
}

const CalendarEventRequirements = ({
  event,
}: ICalendarEventRequirementsProp) => {
  const { currentCoachTeam } = useRootStore();
  const { data, loading } = useQuery(GET_CAMP_REGISTRATION_REQUIREMENTS, {
    variables: {
      team_id: currentCoachTeam?.id,
      camp_id: Number(event.id),
    },
    fetchPolicy: "network-only",
  });

  const [addCampRegistrationRequirement] = useMutation(
    CREATE_CAMP_REGISTRATION_REQUIREMENT,
    {
      onCompleted: (result) => {
        if (result.addCampRegistrationRequirement) {
          toast.success("Registration requirement added to camp");
        } else {
          toast.error("Something went wrong while trying to add option.");
        }
      },
    },
  );

  const [removeCampRegistrationRequirement] = useMutation(
    DELETE_CAMP_REGISTRATION_REQUIREMENT,
    {
      onCompleted: (result) => {
        if (result.removeCampRegistrationRequirement) {
          toast.success("Registration requirement deleted");
        } else {
          toast.error("Something went wrong while trying to delete option.");
        }
      },
    },
  );

  const optionToggle = (requirement, isChecked) => {
    const refetch = [
      {
        query: GET_CAMP_REGISTRATION_REQUIREMENTS,
        variables: {
          team_id: currentCoachTeam?.id,
          camp_id: Number(event.id),
        },
      },
    ];
    if (isChecked) {
      addCampRegistrationRequirement({
        variables: {
          campId: Number(event.id),
          registrationRequirementId: requirement.registration_requirement_id,
        },
        refetchQueries: refetch,
      });
    } else {
      if (
        window.confirm(
          "Are you sure you want to delete this option: " + requirement.name,
        )
      ) {
        removeCampRegistrationRequirement({
          variables: {
            campId: Number(event.id),
            registrationRequirementId: requirement.registration_requirement_id,
          },
          refetchQueries: refetch,
        });
      }
    }
  };
  return (
    <Card>
      <Card.Header>
        <Card.Title>Registration Requirements</Card.Title>
      </Card.Header>
      <Card.Body>
        {!loading &&
          data?.campRegistrationRequirements.map((requirement) => {
            // Only show NAC option if the event is a Competition
            if (
              requirement.requirement_type_slug === "nac" &&
              Number(event.event_type_id) !== 2
            )
              return "";
            // Only show Jump Count option if the Sport Type is Skydiving or BASE
            if (
              requirement.requirement_type_slug === "jump_count" &&
              ![2, 3].includes(Number(event.sport_type_id))
            )
              return "";
            // Only show Tunnel Time option if the Sport Type is Tunnel
            if (
              requirement.requirement_type_slug === "tunnel_time" &&
              ![1].includes(Number(event.sport_type_id))
            )
              return "";
            return (
              <Grid.Row key={requirement.id}>
                <Grid.Col>
                  <Form.Checkbox
                    name="status"
                    label={<p className="mb-0">{requirement.name}</p>}
                    checked={requirement.is_exist}
                    onChange={(e) => {
                      optionToggle(requirement, e.target.checked);
                    }}
                  />
                </Grid.Col>
              </Grid.Row>
            );
          })}
      </Card.Body>
    </Card>
  );
};

export default CalendarEventRequirements;
