import { useLazyQuery, useMutation } from "@apollo/client";
import { EUserFilterOptions } from "components/enum";
import Downshift from "downshift";
import { Formik } from "formik";
import { ACCOUNT_BALANCE_TRANSACTION } from "graphql/ACCOUNT_BALANCE_TRANSACTION";
import { GET_ACCOUNT_BALANCE } from "graphql/GET_ACCOUNT_BALANCE";
import { GET_STUDENT_TEAM_ACCOUNT_BALANCE_HISTORY } from "graphql/GET_STUDENT_TEAM_ACCOUNT_BALANCE_HISTORY";
import { GET_STUDENT_TIME_TRANSACTION_HISTORY } from "graphql/GET_STUDENT_TIME_TRANSACTION_HISTORY";
import { GET_STUDENTS_BY_FILTER_QUERY } from "graphql/GET_STUDENTS_BY_FILTER_QUERY";
import { GET_STUDENTS_ON_DASHBOARD } from "graphql/GET_STUDENTS_ON_DASHBOARD";
import { GET_ACCOUNT_BALANCE as GET_ACCOUNT_BALANCE_TYPE } from "graphql/types/GET_ACCOUNT_BALANCE";
import { EOrderOptions, IArgsOptions } from "hooks/useAutocomplete";
import { useRootStore } from "hooks/useRootStore";
import debounce from "lodash.debounce";
import FeatureAccessGate from "modules/common/components/FeatureAccessGate";
import React, { FC, useMemo, useRef, useState } from "react";
import { useParams } from "react-router";
import { toast } from "react-toastify";
import { Button, Form, Text } from "tabler-react";
import { formatAccountBalance } from "utils/formatAccountBalance";
import { parseIntNullable } from "utils/numberParser";
import { capitalize } from "utils/stringFormat";
import * as Yup from "yup";
import useGetCurrentUserType from "modules/common/hooks/useGetCurrentUserType";


export const TransactionType = {
  CREDIT: "Credit",
  REMOVE: "Remove",
  TRANSFER: "Transfer",
};

export const TransactionTypeId = {
  CREDIT: 5,
  REMOVE: 2,
  TRANSFER: 3,
};

const TransactionColor = {
  CREDIT: "primary",
  REMOVE: "secondary",
  TRANSFER: "warning",
};

const TransactionIcon = {
  CREDIT: "plus",
  REMOVE: "minus",
  TRANSFER: "arrow-right",
};

const SEARCH_LIMIT = 15;

interface AccountBalanceFormProps {
  accountTypeId: number;
  accountTypeSlug: string;
  defaultTransactionType: keyof typeof TransactionType;
  studentBalance: string | number;
  studentName: string;
  handleSetAccountBalanceFormShown: (value: boolean) => void;
  isRegistered: boolean;
}

const AccountBalanceForm: FC<AccountBalanceFormProps> = ({
  accountTypeId,
  accountTypeSlug,
  defaultTransactionType,
  studentBalance,
  studentName,
  handleSetAccountBalanceFormShown,
  isRegistered,
}) => {
  const { userId, teamId } = useParams<{ userId: string; teamId: string }>();
  const { currentUser } = useRootStore();
  const { isAdmin, isCoachSuperAdmin } =
    useGetCurrentUserType();
  const teamIdValue = parseIntNullable(teamId);
  const userIdValue = parseIntNullable(userId);

  const [isAccountBalanceNotesOpen, setIsAccountBalanceNotesOpen] =
    useState(false);
  const [isTransferToModalOpen, setIsTransferToModalOpen] = useState(false);
  const [transferToId, setTransferToId] = useState<string | null>(null);
  const [transactionType, setTransactionType] = useState<
    keyof typeof TransactionType
  >(defaultTransactionType);

  const searchRef = useRef("");

  const argsOptions: IArgsOptions = {
    order: EOrderOptions.alphabet,
    filter: {
      team_id: teamId,
      filter_by: EUserFilterOptions.enabled,
    },
  };

  const [students, { loading, data }] = useLazyQuery(
    GET_STUDENTS_BY_FILTER_QUERY,
  );

  const [
    accountBalance,
    { loading: accountBalanceLoading, data: accountBalanceData },
  ] = useLazyQuery<GET_ACCOUNT_BALANCE_TYPE>(GET_ACCOUNT_BALANCE);

  const balance = useMemo(() => {
    if (!accountBalanceData?.getAccountBalance) {
      return null;
    }

    return formatAccountBalance(
      accountBalanceData?.getAccountBalance.total_time,
      accountBalanceData?.getAccountBalance.account_type_slug,
    );

  }, [accountBalanceData?.getAccountBalance, accountBalanceLoading]);

  const filteredStudents = useMemo(() => {
    if (!data) {
      return [];
    }

    return data[Object.keys(data)[0]];

  }, [data?.students]);

  const toggleAccountBalanceNotes = () =>
    setIsAccountBalanceNotesOpen(!isAccountBalanceNotesOpen);
  const toggleTransferToModal = () =>
    setIsTransferToModalOpen(!isTransferToModalOpen);

  const [accountBalanceTransaction] = useMutation(ACCOUNT_BALANCE_TRANSACTION);

  const handleInputTransferToStudent = debounce(async (inputValue: string) => {
    students({
      variables: {
        ...argsOptions,
        filter: {
          ...argsOptions.filter,
          limit: SEARCH_LIMIT,
          search: inputValue,
        },
      },
    });
  }, 360);

  const renderForm = ({
    handleBlur,
    handleChange,
    handleSubmit,
    isSubmitting,
    setFieldValue,
    values,
  }) => (
    <form>
      <div className="">
        {!isTransferToModalOpen && !isAccountBalanceNotesOpen && (
          <>
            <Form.Group>
              {accountTypeSlug === "time" ? (
                <Form.MaskedInput
                  autoFocus
                  className={"text-center text-xxl form-control"}
                  placeholder="00:00"
                  mask={[/\d/, /\d/, ":", /\d/, /\d/]}
                  name="rawValue"
                  onChange={(e) => {
                    const inputArray = e.target.value.split(":");
                    const inputTime =
                      parseIntNullable(inputArray[0]) * 60 +
                      parseIntNullable(inputArray[1]);
                    setFieldValue("minutes", inputTime);
                    setFieldValue("rawValue", e.target.value);
                  }}
                  key={transactionType}
                />
              ) : (
                <Form.Input
                  autoFocus
                  className={"text-center text-xxl"}
                  placeholder=""
                  key={transactionType}
                  name="rawValue"
                  type="number"
                  onChange={(e) => {
                    setFieldValue("minutes", Number(e.target.value));
                    setFieldValue("rawValue", e.target.value);
                  }}
                />
              )}
            </Form.Group>
            <Button.List align="center">
              <Button
                icon={TransactionIcon["CREDIT"]}
                color={TransactionColor["CREDIT"]}
                disabled={!values.minutes}
                onClick={(e) => {
                  e.preventDefault();
                  setTransactionType("CREDIT");
                  toggleAccountBalanceNotes();
                }}
              >
                CREDIT
              </Button>
              <Button
                icon={TransactionIcon["REMOVE"]}
                color={TransactionColor["REMOVE"]}
                disabled={(!isRegistered && !isAdmin && !isCoachSuperAdmin) || !values.minutes}
                onClick={(e) => {
                  e.preventDefault();
                  setTransactionType("REMOVE");
                  toggleAccountBalanceNotes();
                }}
              >
                REMOVE
              </Button>

              <FeatureAccessGate feature="feature_transfer_time">
                <Button
                  icon={TransactionIcon["TRANSFER"]}
                  color={TransactionColor["TRANSFER"]}
                  disabled={!values.minutes}
                  onClick={(e) => {
                    e.preventDefault();
                    setTransactionType("TRANSFER");
                    toggleTransferToModal();
                  }}
                >
                  TRANSFER
                </Button>
              </FeatureAccessGate>
            </Button.List>{" "}
          </>
        )}
        {isAccountBalanceNotesOpen && (
          <>
            <Form.Group
              label={`Amount to ${capitalize(transactionType.toLowerCase())}:`}
            />
            <Form.Group className={"text-center"}>
              <text className="text-primary font-weight-bold mx-auto text-xxl ">
                {formatAccountBalance(values.minutes, accountTypeSlug)}
              </text>
            </Form.Group>

            <Form.Group label="Notes">
              <Form.Textarea
                name="notes"
                onBlur={handleBlur}
                onChange={handleChange}
                placeholder="Add notes (optional)"
                rows={3}
                type="text"
                value={values.notes}
              />
            </Form.Group>

            <Button.List className="mt-4" align="right">
              <Button
                pill
                color="white"
                size="sm"
                onClick={toggleAccountBalanceNotes}
              >
                CANCEL
              </Button>
              <Button
                disabled={isSubmitting}
                loading={isSubmitting}
                onClick={handleSubmit}
                pill
                color={TransactionColor[transactionType]}
                size="sm"
                type="submit"
              >
                SUBMIT
              </Button>
            </Button.List>
          </>
        )}

        {isTransferToModalOpen && (
          <>
            <Form.Group
              label={`Amount to ${capitalize(transactionType.toLowerCase())}:`}
            />
            <Form.Group className={"text-center"}>
              <text className="text-primary font-weight-bold mx-auto text-xxl ">
                {formatAccountBalance(values.minutes, accountTypeSlug)}
              </text>
            </Form.Group>
            <Form.Group>
              <Form.Label htmlFor="search">Transfer from:</Form.Label>
              <Text>
                {studentName} ({studentBalance})
              </Text>
            </Form.Group>
            <Form.Group>
              <Form.Label htmlFor="search">Transfer to:</Form.Label>
              <div className="search-container w-150">
                <Downshift
                  onChange={(selectedItem) => {
                    setTransferToId(selectedItem.id);
                    searchRef.current = `${selectedItem.first_name} ${selectedItem.last_name}`;

                    accountBalance({
                      variables: {
                        studentId: selectedItem.id,
                        teamId: teamIdValue,
                      },
                    });
                  }}
                >
                  {({
                    getInputProps,
                    getItemProps,
                    isOpen,
                    inputValue,
                    highlightedIndex,
                  }) => (
                    <div>
                      <input
                        {...getInputProps({
                          className: "form-control",
                          id: "search",
                          name: "transferTo",
                          placeholder: "Search by name...",
                          type: "search",
                          value: searchRef.current,
                          onChange: (e) => {
                            searchRef.current = e.target.value;
                            e.persist();
                            handleInputTransferToStudent(e.target.value);
                          },
                        })}
                      />
                      {isOpen && (
                        <div className="search-container-dropdown">
                          {filteredStudents.map((item, idx) => (
                            <div
                              {...getItemProps({ item })}
                              className={`search-container-dropdown-item ${
                                idx === highlightedIndex &&
                                "search-container-dropdown-item-highlighted"
                              }`}
                              key={item.id}
                            >
                              <span>
                                {`${item.first_name} ${item.last_name}`}
                              </span>
                              <span className="d-block small text-primary ml-1">
                                {item.id}
                              </span>
                            </div>
                          ))}
                          {!filteredStudents.length && !loading && (
                            <div className="search-container-dropdown-item">
                              No results for {inputValue}.
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  )}
                </Downshift>
                {balance && (
                  <Text className="mt-2">
                    {searchRef.current} {balance}
                  </Text>
                )}
              </div>
            </Form.Group>

            <Form.Group label="Notes">
              <Form.Textarea
                name="notes"
                onBlur={handleBlur}
                onChange={handleChange}
                placeholder="Add notes (optional)"
                rows={3}
                type="text"
                value={values.notes}
              />
            </Form.Group>
            <Button.List className="mt-4" align="right">
              <Button
                pill
                color="white"
                size="sm"
                onClick={toggleTransferToModal}
              >
                CANCEL
              </Button>
              <Button
                disabled={isSubmitting || !transferToId}
                loading={isSubmitting}
                onClick={handleSubmit}
                pill
                color={TransactionColor[transactionType]}
                size="sm"
                type="submit"
              >
                SUBMIT
              </Button>
            </Button.List>
          </>
        )}
      </div>
    </form>
  );

  return (
    <Formik
      enableReinitialize={true}
      validationSchema={Yup.object().shape({
        minutes: Yup.number().required("Required"),
        notes: Yup.string().nullable(),
      })}
      initialValues={{
        rawValue: "",
        minutes: 0,
        notes: "",
      }}
      onSubmit={async (values, { resetForm, setSubmitting }) => {
        const { minutes, notes } = values;

        try {
          await accountBalanceTransaction({
            variables: {
              payload: {
                account_type_id: accountTypeId,
                created_by: currentUser.id,
                minutes: transactionType === "CREDIT" ? minutes : -minutes,
                notes,
                student_id: userId,
                team_id: teamIdValue,
                transfer_to: transferToId,
                transaction_type_id: TransactionTypeId[transactionType],
              },
            },
            refetchQueries: [
              {
                query: GET_ACCOUNT_BALANCE,
                variables: {
                  studentId: userId,
                  teamId: teamIdValue,
                },
              },
              {
                query: GET_STUDENT_TEAM_ACCOUNT_BALANCE_HISTORY,
                variables: {
                  studentId: userId,
                  teamId: teamIdValue,
                  userType: currentUser.type,
                },
              },
              {
                query: GET_STUDENT_TIME_TRANSACTION_HISTORY,
                variables: {
                  studentId: userIdValue,
                  teamId: teamIdValue,
                  userType: currentUser.type,
                },
              },
              {
                query: GET_STUDENTS_ON_DASHBOARD,
                variables: {
                  team_id: teamId,
                },
              },
            ],
          });
        } catch (error) {
          toast.error(error);
        }

        setSubmitting(false);
        handleSetAccountBalanceFormShown(false);

        toast.success("Transaction saved");

        resetForm();
      }}
    >
      {(formikData) => renderForm(formikData)}
    </Formik>
  );
};

export default AccountBalanceForm;
