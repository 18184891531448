import { useMutation, useQuery } from "@apollo/client";
import { Formik } from "formik";
import { CREATE_CAMP_REGISTRATION_OPTION } from "graphql/CREATE_CAMP_REGISTRATION_OPTION";
import { DELETE_CAMP_REGISTRATION_OPTION } from "graphql/DELETE_CAMP_REGISTRATION_OPTION";
import { GET_CAMP_REGISTRATION_OPTIONS } from "graphql/GET_CAMP_REGISTRATION_OPTIONS";
import React from "react";
import Select from "react-select";
import { toast } from "react-toastify";
import { Button, Card, Form, Grid, Text } from "tabler-react";
import { formatMoney } from "utils/numberFormat";
import * as Yup from "yup";
import { CREATE_REGISTRATION_OPTION } from "../../../graphql/CREATE_REGISTRATION_OPTION";
import { GET_CAMP_OPTION_TYPES } from "../../../graphql/GET_CAMP_OPTION_TYPES";
import { Types } from "../../../types/graphql";
import FormField from "../../FormField";
import Modal from "../../Modal";
import useGetCurrentUserType from "../../../modules/common/hooks/useGetCurrentUserType";


interface ICalendarEventRegistrationsProp {
  event: Types.Camp;
}

const CalendarEventRegistrations = ({
  event,
}: ICalendarEventRegistrationsProp) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const { isAdmin, isCoachSuperAdmin } = useGetCurrentUserType();

  const { data, loading } = useQuery(GET_CAMP_REGISTRATION_OPTIONS, {
    variables: {
      campId: Number(event.id),
      teamId: Number(event?.team_id),
    },
  });

  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  const [addCampRegistrationOptions] = useMutation(
    CREATE_CAMP_REGISTRATION_OPTION,
    {
      onCompleted: (result) => {
        if (result.addCampRegistrationOptions) {
          toast.success("Registration option added to camp");
        } else {
          toast.error("Something went wrong while trying to add option.");
        }
      },
    },
  );

  const [deleteCampRegistrationOption] = useMutation(
    DELETE_CAMP_REGISTRATION_OPTION,
    {
      onCompleted: (result) => {
        if (result.deleteCampRegistrationOption) {
          toast.success("Registration option deleted");
        } else {
          toast.error("Something went wrong while trying to delete option.");
        }
      },
    },
  );

  const optionToggle = (option, isChecked) => {
    if (isChecked) {
      addCampRegistrationOptions({
        variables: {
          campRegistrationOption: {
            camp_id: Number(event.id),
            registration_option_id: option.id,
          },
        },
        refetchQueries: [
          {
            query: GET_CAMP_REGISTRATION_OPTIONS,
            variables: {
              campId: Number(event.id),
              teamId: Number(event?.team_id),
            },
          },
        ],
      });
    } else {
      if (
        window.confirm(
          "Are you sure you want to delete this option: " + option.name,
        )
      ) {
        deleteCampRegistrationOption({
          variables: {
            campId: Number(event.id),
            registrationOptionId: option.id,
          },
          refetchQueries: [
            {
              query: GET_CAMP_REGISTRATION_OPTIONS,
              variables: {
                campId: Number(event.id),
                teamId: Number(event?.team_id),
              },
            },
          ],
        });
      }
    }
  };

  return (
    <Card>
      <Card.Header>
        <Card.Title>Registration Options</Card.Title>
        <Card.Options>
          <Button
            outline
            icon={"plus"}
            color={"primary"}
            size={"sm"}
            onClick={toggleModal}
            disabled={!isAdmin && !isCoachSuperAdmin}
          />
        </Card.Options>
      </Card.Header>
      <Card.Body>
        {!loading &&
          data?.getAvailableCampRegistrationOptions.map((option) => {
            return (
              <Grid.Row key={option.id}>
                <Grid.Col>
                  <Form.Checkbox
                    name="status"
                    label={
                      <>
                        <p className="mb-0">{option.name}</p>
                        <Text.Small className="text-muted">
                          {formatMoney(Number(option.option_value))}{" "}
                          {option.fee_type_name}
                        </Text.Small>
                      </>
                    }
                    checked={option.is_exist}
                    onChange={(e) => {
                      optionToggle(option, e.target.checked);
                    }}
                  />
                </Grid.Col>
              </Grid.Row>
            );
          })}
      </Card.Body>
      <AddCampOptionModal
        campId={event.id}
        isOpen={isOpen}
        toggle={toggleModal}
      />
    </Card>
  );
};

export default CalendarEventRegistrations;

const AddCampOptionValidationSchema = Yup.object().shape({
  name: Yup.string().required("This field is required."),
  description: Yup.string().required("This field is required."),
  option_type_id: Yup.string().required("This field is required."),
  option_value: Yup.string().required("This field is required."),
});
const AddCampOptionModal = ({ campId, isOpen, toggle }) => {
  const { data: campOptionTypes } = useQuery(GET_CAMP_OPTION_TYPES);

  const registration_option_types =
    campOptionTypes?.getRegistrationOptionTypes.filter((option) =>
      [3, 5].includes(Number(option.id)),
    ) || [];

  const [createNewRegistrationOption] = useMutation(
    CREATE_REGISTRATION_OPTION,
    {
      onCompleted: () => toast.success(`Registration option saved.`),
    },
  );
  return (
    <Modal
      open={isOpen}
      onClose={toggle}
      title="Add Registration Option"
      content={
        <Formik
          initialValues={{
            name: "",
            description: "",
            option_type_id: "",
            option_value: "",
            camp_id: campId,
            reg_form: 0,
            apply_sanction_fee: 0,
          }}
          validationSchema={AddCampOptionValidationSchema}
          onSubmit={async (values, { resetForm, setSubmitting }) => {
            await createNewRegistrationOption({
              variables: {
                name: values.name,
                description: values.description,
                option_value: String(values.option_value),
                option_type_id: values.option_type_id,
                camp_id: campId,
                reg_form: values.reg_form,
                apply_sanction_fee: values.apply_sanction_fee,
              },
            });
            resetForm();
            setSubmitting(false);
            toggle();
          }}
        >
          {({
            values,
            handleChange,
            handleSubmit,
            setFieldValue,
            errors,
            touched,
          }) => (
            <Form id="registrationOptionForm" onSubmit={handleSubmit}>
              <Grid.Row>
                <Grid.Col>
                  <FormField
                    name="name"
                    label="Name"
                    placeholder="Name"
                    onChange={handleChange}
                    value={values.name}
                  />
                </Grid.Col>
              </Grid.Row>

              <Grid.Row>
                <Grid.Col>
                  <FormField
                    multiline="true"
                    name="description"
                    label="Description"
                    placeholder="Description"
                    onChange={handleChange}
                    value={values.description}
                  />
                </Grid.Col>
              </Grid.Row>

              <Grid.Row>
                <Grid.Col>
                  <Form.Group>
                    <Form.Label>Option Type</Form.Label>
                    <Select
                      styles={{
                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                      }}
                      menuPortalTarget={document.body}
                      backspaceRemovesValue={true}
                      escapeClearsValue={true}
                      getOptionLabel={(option) => option.name}
                      getOptionValue={(option) => option.id}
                      isClearable={true}
                      name="option_type_id"
                      options={registration_option_types}
                      onChange={(params) => {
                        setFieldValue("option_type_id", params.id);
                      }}
                      value={
                        values.option_type_id &&
                        registration_option_types.find(
                          (regOptType) =>
                            regOptType.id === values.option_type_id,
                        )
                      }
                    />
                    <span className="field-error text-danger">
                      {errors.option_type_id &&
                        touched.option_type_id &&
                        errors.option_type_id}
                    </span>
                  </Form.Group>
                </Grid.Col>
                <Grid.Col>
                  <FormField
                    name="option_value"
                    label="Option Value"
                    placeholder="$0.00"
                    onChange={handleChange}
                    type="number"
                    value={values.option_value}
                  />
                </Grid.Col>
              </Grid.Row>

              <Grid.Row>
                <Grid.Col>
                  <Form.Group label={"Registration Form"}>
                    <Form.Switch
                      name="reg_form"
                      checked={!!values.reg_form}
                      onChange={(e) => {
                        setFieldValue("reg_form", e.target.checked ? 1 : 0);
                      }}
                    />
                  </Form.Group>
                </Grid.Col>
                <Grid.Col>
                  <Form.Group label={"Apply Sanction Fee"}>
                    <Form.Switch
                      name="apply_sanction_fee"
                      checked={!!values.apply_sanction_fee}
                      onChange={(e) => {
                        setFieldValue(
                          "apply_sanction_fee",
                          e.target.checked ? 1 : 0,
                        );
                      }}
                    />
                  </Form.Group>
                </Grid.Col>
              </Grid.Row>
              <Grid.Row>
                <Grid.Col>
                  <Button.List align="right">
                    <Button
                      pill
                      color="white"
                      size="sm"
                      onClick={(e) => {
                        e.preventDefault();
                        toggle();
                      }}
                    >
                      CANCEL
                    </Button>
                    <Button
                      pill
                      type="submit"
                      form="registrationOptionForm"
                      className="btn btn-primary"
                      color="gray-dark"
                      size="sm"
                    >
                      SAVE
                    </Button>
                  </Button.List>
                </Grid.Col>
              </Grid.Row>
            </Form>
          )}
        </Formik>
      }
    />
  );
};
