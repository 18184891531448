import { useQuery } from "@apollo/client";
import OnDeckListItem from "components/OnDeck/OnDeckListItem";
import { GET_STUDENTS_ON_DASHBOARD } from "graphql/GET_STUDENTS_ON_DASHBOARD";
import { GET_STUDENTS_ON_DASHBOARD as GET_STUDENTS_ON_DASHBOARD_TYPE } from "graphql/types/GET_STUDENTS_ON_DASHBOARD";
import { useRootStore } from "hooks";
import { observer } from "mobx-react";
import React from "react";
import { Dimmer, Grid, Header } from "tabler-react";
import { useRoles } from "modules/common/hooks/useGetCurrentUserType";

const StudentsOnDashboard = ({ missingInfo }) => {
  const { currentCoachTeam } = useRootStore();
  const { isCoach } = useRoles();
  const { loading, data } = useQuery<GET_STUDENTS_ON_DASHBOARD_TYPE>(
    GET_STUDENTS_ON_DASHBOARD,
    {
      variables: {
        team_id: currentCoachTeam?.id,
      },
    },
  );

  const onDeckStudentRows = data?.studentsOnDashboard.map((item) => item) ?? [];

  return (
    <Dimmer active={loading} loader={loading}>
      <Grid.Col className="pl-0 pr-0" width={12}>
        {missingInfo && (
          <>
            <Grid.Row>
              {onDeckStudentRows
                .filter(
                  ({ studentDetails }) =>
                    !studentDetails.email || !studentDetails.registration_id,
                )
                .map((onDeck) => (
                  <Grid.Col lg={3} sm={6} xs={12} key={onDeck.id}>
                    <OnDeckListItem onDeck={onDeck} />
                  </Grid.Col>
                ))}
            </Grid.Row>
          </>
        )}
        {!missingInfo && (
          <Grid.Row>
            {onDeckStudentRows
              .filter(
                ({ studentDetails }) =>
                  studentDetails.email && studentDetails.registration_id,
              )
              .map((onDeck) => (
                <Grid.Col lg={3} sm={6} xs={12} key={onDeck.id}>
                  <OnDeckListItem onDeck={onDeck} isEditable={true} />
                </Grid.Col>
              ))}
          </Grid.Row>
        )}
      </Grid.Col>
    </Dimmer>
  );
};

export default observer(StudentsOnDashboard);
