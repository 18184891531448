import React, { FC } from "react";
import { NumericFormat, NumericFormatProps } from "react-number-format";
import { Form } from "tabler-react";
import { getCurrencySymbol } from "../utils/numberFormat";

interface CurrencyInputFieldProps {
  name: string;
  onChangeSideEffect?: (value: number) => void;
  setFieldValue: (field: string, value: number) => void;
  currency?: string;
}

const CurrencyInputField: FC<CurrencyInputFieldProps & NumericFormatProps> = ({
  name,
  value,
  onChangeSideEffect,
  setFieldValue,
  currency,
  ...restProps
}) => {
  return (
    <NumericFormat
      customInput={Form.Input}
      name={name}
      prefix={getCurrencySymbol(currency ?? "USD")}
      thousandSeparator
      onChange={(e) => {
        const value = e.target.value.replace(/[\D,]+/g, "");
        setFieldValue(name, Number(value));
        onChangeSideEffect?.(Number(value));
      }}
      value={value}
      {...restProps}
    />
  );
};

export default CurrencyInputField;
