import gql from "graphql-tag";

export const GET_USER_REGISTRATIONS_BY_IDS = gql`
  query GET_USER_REGISTRATIONS_BY_IDS($userRegistrationsId: Int!) {
    getUserRegistrationsByIds(userRegistrationsId: $userRegistrationsId) {
      camp_fee_type
      camp_fees
      camp_id
      camp_name
      campLocation
      campScoreCards
      eventTypeId
      created_on
      default_jump_count
      default_jump_price
      discipline
      disciplineId
      jump_quantity
      email
      end
      student_id
      first_name
      last_name
      location
      profile_picture
      profile_avatar
      registration_code
      rating
      role
      roleId
      slot_duration
      start
      status
      team_id
      team_currency
      team_name
      user_registrations_id
      userScoreCard
      phone_number
      phone_country_code
      weight
      height
      dob
      shirt_size_id

      confirmedSlots {
        start
        end
        user_duration
        slot_option {
          id
          name
          description
          option_value
          option_fee_type_id
        }
        slotCoach {
          first_name
          last_name
        }
        registration_option {
          id
          name
          description
          option_value
          option_fee_type_id
        }
      }

      payments {
        id
        amount
        total
        fees
        processed_by
        manual_payment
        status
        created_on
        coach_first_name
        coach_last_name
      }

      paymentDetail {
        total
        outstanding
        totalPaid
        totalFees
        totalComp
      }

      totalTime {
        total_duration
      }
      user_registration_options {
        id
        registration_option_id
        name
        option_value
        quantity
        fee_type
      }
    }
  }
`;
